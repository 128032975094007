import axios from './api';

export default {
	login(username, password, callback) {
		callback = arguments[arguments.length - 1];
		if (localStorage.token) {
			if (callback) {
				callback(true);
			}
			this.onChange(true);
			return;
		}
		apiLogin(username, password, (result) => {
			if (result.authenticated) {
				localStorage.token = result.token;
				localStorage.vendorNames = [];
				if (result.loginData.vendors !== undefined && result.loginData.vendors.length) {
					localStorage.vendorNames = result.loginData.vendors.map(vendor => {
						return vendor.organization.name;
					});	
				}
				if (callback) {
					callback(true);
				}
				this.onChange(true);
			} else {
				if (callback) {
					callback(false);
				}
				this.onChange(false);
			}
		})
	},
  
	getToken() {
		return localStorage.token
	},

	getVendorNames() {
		return localStorage.vendorNames
	},

	isVendor() {
		return localStorage.vendorNames !== undefined && !!localStorage.vendorNames.length
	},
  
	logout(callback) {
		delete localStorage.token
		if (callback) {
			callback();
		}
		this.onChange(false)
	},
  
	loggedIn() {
		return !!localStorage.token
	},
  
	onChange() {}
}
  
function apiLogin(username, password, callback) {
	let url = '/oauth/access-token';
	axios.post(
		url, {
			client_id: process.env.VUE_APP_OAUTH2_CLIENT_ID,
			client_secret: process.env.VUE_APP_OAUTH2_CLIENT_SECRET,
			grant_type: "password",
			password: password,
			username: username
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	)
	.then(response => {
		console.log(response.data.data);
		callback({
			authenticated: true,
			token: response.data.access_token,
			loginData: response.data
		});
	});
}
