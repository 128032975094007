<template>
	<v-snackbar
		v-model="snackbar"
		:bottom="y === 'bottom'"
		:color="color"
		:left="x === 'left'"
		:multi-line="mode === 'multi-line'"
		:right="x === 'right'"
		:timeout="timeout"
		:top="y === 'top'"
		:vertical="mode === 'vertical'"
	>
		{{ message }}
		<template v-slot:action="{ attrs }">
			<v-btn
				dark
				text
				v-bind="attrs"
				@click="snackbar = false"
			>
				Close
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
	export default {
		data() {
			return {
				snackbar: false,
				top: true,
				message: "",
				mode: "",
				color: "",
				timeout: 5000,
				x: null,
				y: 'top',
			};
		},
		methods: {
			showNotification(message, color) {
				this.snackbar = true;
				this.message = message;
				this.color = color;
			},
		}
	};
</script>