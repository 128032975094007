import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import auth from './auth'
import vuetify from './plugins/vuetify';
import axios from './api'
import App from './components/App.vue'
import Login from './components/Login.vue'
import Order from './components/Order.vue'

Vue.config.productionTip = false

function requireAuth (to, from, next) {
	if (!auth.loggedIn()) {
		next({
			path: '/login',
			query: { redirect: to.fullPath }
		});
	} else {
		next();
	}
}

const router = new VueRouter({
	mode: 'history',
	base: __dirname,
	routes: [
		{ path: '/order/:orderId', component: Order, props: true, beforeEnter: requireAuth },
		{ path: '/login', component: Login },
		{ path: '/logout',
			beforeEnter (to, from, next) {
				auth.logout();
				next('/');
			}
		}
	]
})

axios.interceptors.response.use(response => {
		if (response.status === 200 || response.status === 201) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	(error) => {
		if (error.message && error.message === 'Network Error' && error.response === undefined) {
			auth.logout();
			router.replace({
				path: "/login",
				query: { redirect: router.currentRoute.fullPath }
			});
			return Promise.reject(error.message);
		}
		console.log(error.message);
		console.log(error);
		if (error.response !== undefined && error.response.status) {
			alert(error.response);
			switch (error.response.status) {
		// 		case 400:
		// 			//do something
		// 			break;
				case 401:
					// Assume 401 without CORS header.
					auth.logout();	
					router.replace({
						path: "/login",
						query: { redirect: router.currentRoute.fullPath }
					});
					break;
				case 403:
					alert(error.response.data.error.message);
					break;
				case 404:
					alert('page not exist');
					break;
		// 		case 502:
		// 			setTimeout(() => {
		// 				router.replace({
		// 					path: "/login",
		// 					query: {
		// 						redirect: router.currentRoute.fullPath
		// 					}
		// 				});
		// 			}, 1000);
			}
			return Promise.reject(error.response);
		}
	}
);

new Vue({
	axios,
	vuetify,
	router,
	render: h => h(App)
}).$mount('#app')
