<template>
	<v-app>
		<v-app-bar
			app
			color="primary"
			dark
		>
			<div class="d-flex align-center">
				<v-img
					alt="Vuetify Logo"
					class="shrink mr-2"
					contain
					src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
					transition="scale-transition"
					width="40"
				/>
				<h1>MultiShip App</h1>
			</div>
      <v-spacer></v-spacer>
			<v-btn v-if="loggedIn" to="/logout">Log out</v-btn>
			<v-btn v-if="!loggedIn" to="/login">Log in</v-btn>
    </v-app-bar>
		<v-main>
			<template v-if="$route.matched.length">
				<router-view></router-view>
			</template>
			<template v-else>
				<p>You are logged {{ loggedIn ? 'in' : 'out' }}</p>
			</template>
		</v-main>
	</v-app>
</template>

<script>
import auth from '../auth'
export default {
	data () {
		return {
			loggedIn: auth.loggedIn()
		}
	},
	created () {
		auth.onChange = loggedIn => {
			this.loggedIn = loggedIn
		}
	}
}
</script>