<template>
	<v-container>
		<v-row>
			<v-col cols=12>
				<v-form ref="form">
					<v-container>
						<v-row>
							<v-col cols=12 md=4>
								<h3>Address</h3>
							</v-col>
							<v-col cols=12 md=4>
								<h3>Items</h3>
							</v-col>
							<v-col cols=12 md=4>
								<h3>Details</h3>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols=12 md=4>
								<v-text-field v-model="shipTo" label="Ship To" required></v-text-field>
								<v-text-field v-model="address1" label="Address 1" required></v-text-field>
								<v-text-field v-model="address2" label="Address 2"></v-text-field>
								<v-text-field v-model="city" label="City" required></v-text-field>
								<v-text-field v-model="state" label="State" required></v-text-field>
								<v-text-field v-model="zip" label="Zipcode" required></v-text-field>
							</v-col>
							<v-col cols=12 md=4>
								<p v-for="(item, i) in items" :key="i" class="text-body-2">
									<span>{{item.po}} - </span>
									<span>{{item.style}} - </span>
									<span class="text-uppercase">{{item.color}} - </span>
									<span>{{item.size}} </span>
									<span>({{item.quantity}})</span>
									<v-icon v-on:click.stop="removeItem(items, item)" right dark>mdi-delete-forever</v-icon>
								</p>
								<v-select :items="this.designOptions.pos" v-model="po" label="Design"></v-select>
								<v-select :items="this.designOptions.styles" v-model="style" label="Style"></v-select>
								<v-select :items="this.designOptions.colors" v-model="color" label="Color"></v-select>
								<v-select :items="this.designOptions.sizes" v-model="size" label="Size"></v-select>
								<v-text-field v-model="quantity" label="Quantity"></v-text-field>
								<v-btn x-small color="primary" class="float-right" v-on:click.stop="addItemsToShipment(shipment)">Add Item(s)</v-btn>
							</v-col>
							<v-col cols="12" md=4>
								<v-text-field v-model="labelMsg1" label="Label Msg 1"></v-text-field>
								<v-text-field v-model="labelMsg2" label="Label Msg 2"></v-text-field>
								<v-textarea
									label="Notes"
									v-model="notes"
									name="input-7-1"
									value=""
								></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
							<v-btn 
								v-if="shipment.dirty || shipment.editing" 
								small class="ml-2 float-right" color="secondary" 
								v-on:click.stop="shipment.editing = false">Cancel</v-btn>
							<v-btn 
								v-if="shipment.dirty || shipment.editing" 
								small class="ml-2 float-right" color="primary" 
								v-on:click.stop="saveShipment(shipment)">Save</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import axios from '../api';
import _cloneDeep from 'lodash/clone';

export default {
	name: 'ShipmentForm',
	props: ['designOptions', 'shipment'],
	data: () => ({
		shipTo: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		items: {},
		style: '',
		color: '',
		size: '',
		quantity: '',
		labelMsg1: '',
		labelMsg2: '',
		notes: '',
		notifications: {},
		scheduledDatePicker: new Date().toISOString().substr(0, 10),
		dirty: false,
		itemsDirty: false,
		po: ''
	}),
	methods: {
		addItemsToShipment() {
			if (this.color !== ""
				&& this.po !== ""
				&& this.quantity !== ""
				&& this.size !== ""
				&& this.style !== ""
			) {
				let newItem = {
					"color": this.color, 
					"po": this.po,
					"quantity": this.quantity,
					"size": this.size, 
					"style": this.style, 
				};
				newItem = _cloneDeep(newItem);
				this.items.push(newItem);
				this.color = "";
				this.po = ""
				this.quantity = "";
				this.size = "";
				this.style = "";
				this.itemsDirty = true;
			}
		},

		buildShipment() {
			let shipment = _cloneDeep(this.shipment);
			shipment.addressId = null;
			shipment.address.data[0].id = null;
			shipment.address.data[0].shipTo = this.shipTo;
			shipment.address.data[0].address1 = this.address1;
			shipment.address.data[0].address2 = this.address2;
			shipment.address.data[0].city = this.city;
			shipment.address.data[0].state = this.state;
			shipment.address.data[0].stateId = null;
			shipment.address.data[0].zip = this.zip;
			shipment.shipment_items = this.items;
			shipment.ref1 = this.labelMsg1;
			shipment.ref2 = this.labelMsg2;
			shipment.notes = this.notes;
			shipment.scheduledShipDate = this.scheduledDatePicker;
			return shipment;
		},

		cancelEditingShipment() {
			this.shipment.editing = false;
			this.resetForm();
		},

		enterEditingShipment() {
			this.shipment.editing = true;
			this.resetForm();
		},

		removeItem(items, item) {
			items.splice(items.indexOf(item), 1);
			this.itemsDirty = true;
			console.log(this.itemsDirty);
		},

		resetForm() {
			this.shipTo = this.shipment.address.data[0].shipTo;
			this.address1 = this.shipment.address.data[0].address1;
			this.address2 = this.shipment.address.data[0].address2;
			this.city = this.shipment.address.data[0].city;
			this.state = this.shipment.address.data[0].state;
			this.zip = this.shipment.address.data[0].zip;
			this.items = this.shipment.shipment_items;
			this.style = '';
			this.color = '';
			this.size = '';
			this.quantity = '';
			this.labelMsg1 = this.shipment.ref1;
			this.labelMsg2 = this.shipment.ref2;
			this.notes = this.shipment.notes;
			this.scheduledDatePicker = this.shipment.scheduledShipDate;
		},

		saveShipment() {
			let shipment = this.buildShipment();
			let validShipment = this.validateShipment(shipment);
			if (validShipment !== true) {
				this.notifications.showNotification('Shipment is missing details: ' + validShipment, 'error');
				return false;
			}
			console.log(shipment);
			console.log(this.shipment);
			axios.post(`/orders/${this.shipment.orderId}/shipping/save`, {
				shipment
			})
			.then(async response => {
				// Handle response
				this.notifications.showNotification('Successfully saved shipment.', 'success');
				this.shipment.editing = false;
				this.shipment.id = response.data.data.id;
				this.shipment.addressId = response.data.data.addressId;
				this.shipment.notes = response.data.data.notes;
				this.shipment.ref1 = response.data.data.ref1;
				this.shipment.ref2 = response.data.data.ref2;
				this.shipment.dirty = false;
				if (this.itemsDirty) {
					this.updatePackages();
				}
				this.orderMultiship.getCheapestMethod(this.shipment);
				return response.data;
			})
			.catch(async response => {
				console.log(response.data.error.message);
				this.notifications.showNotification(response.data.error.message, 'error');
				console.log('FAILURE!!');
			});
		},

		updatePackages() {
			axios.post(`/orders/shipping/packages/update`, {
				shipmentId: this.shipment.id
			})
			.then(async response => {
				// Handle response
				console.log(response.data.data.packages);
				this.notifications.showNotification('Updated shipment packages.', 'success');
				console.log(this.shipment.packages);
				this.shipment.packages = response.data.data.packages;
				this.shipment.weight = Number.parseFloat(this.shipment.packages.data.reduce(function(a, b) {return a + parseFloat(b.weight); }, 0)).toFixed(2);
				this.itemsDirty = false;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		validateShipment(shipment) {
			let errors = [];
			let fields = [
				'shipment.shippingMethodId', 'shipment.shipment_items', 'address.shipTo',
				'address.address1', 'address.city', 'address.zip'
			];
			fields.forEach(field => {
				console.log(field);
				let obj;
				let fieldParts = field.split('.');
				if (fieldParts[0] === 'shipment') {
					obj = shipment;
				}
				if (fieldParts[0] === 'address') {
					obj = shipment.address.data[0];
				}
				console.log(obj[fieldParts[1]]);
				if (obj[fieldParts[1]] === null || obj[fieldParts[1]].length === 0) {
					errors.push(field);
				}
				console.log(errors);
			});
			if (errors.length) {
				return errors;
			}
			return true;
		},
	},
	mounted() {
		console.log('mounted');
		console.log(this.shipment);
		this.resetForm();
		this.notifications = this.$parent.$parent.$parent.$parent.$parent.$refs.Notifications;
		this.orderMultiship = this.$parent.$parent.$parent.$parent.$parent.$refs.OrderMultiShip;
	},
}
</script>