<template>
	<v-container>
		<v-row>
			<h4 class="text-h4">Order {{order.id}} - {{order.customerName}}</h4>
			<v-spacer></v-spacer>
		</v-row>
		<v-row>
			<v-spacer></v-spacer>
			<v-switch style="margin-top:0" label="Compact" dense inset v-model="order.compactView" class="ml-2 float-right"></v-switch>
			<v-btn small color="primary" v-on:click="newShipment()" class="ml-2 float-right" v-if="!order.isVendor">Add Shipment <v-icon right dark>mdi-truck</v-icon></v-btn>
			<v-btn small color="primary" v-on:click="downloadTemplate()" class="ml-2 float-right">Download <v-icon right dark>mdi-file-download</v-icon></v-btn>
			<v-btn small color="primary" v-on:click="uploadFormOpen = true" class="ml-2 float-right" v-if="!order.isVendor">Upload<v-icon right dark>mdi-file-upload</v-icon></v-btn>
			<v-btn small color="primary" v-on:click="shipAll()" class="ml-2 float-right">Ship All</v-btn>
			<v-btn small color="secondary" v-on:click="cheapestForAll()" class="ml-2 float-right">Cheapest</v-btn>
			<v-btn small color="secondary" v-on:click="voidAll()" class="ml-2 float-right">Void All</v-btn>
			<v-btn small color="secondary" v-on:click="printAll()" class="ml-2 float-right">Print All</v-btn>
			<v-btn small color="secondary" v-on:click="trackingCSV()" class="ml-2 float-right" v-if="!order.isVendor">Tracking <v-icon right dark>mdi-file-download</v-icon></v-btn>
		</v-row>
		<v-row v-show="uploadFormOpen">
			<v-file-input
				id="file"
				ref="file"
				show-size
				accept=".csv"
				label="File input"
				v-on:change="handleFileUpload()"
			></v-file-input>
			<v-btn x-small color="secondary" class="mr-2" v-on:click="uploadFormOpen = false">Cancel</v-btn>
			<v-btn x-small color="primary" v-on:click="submitFile()">Submit</v-btn>
		</v-row>
		<v-row v-for="(design, d) in order.designs.data" :key="d">
			<v-col cols="4" v-if="design.proofs.data" class="d-flex">
				<v-img v-for="(proof, p) in design.proofs.data" :key="p" class="mr-2 proof" max-width="150" max-height="150" contain :src="`https://s3.amazonaws.com/eztees-uploads/orig/${proof.fileName}${proof.origExt}`"/>
			</v-col>
			<v-col cols="1" v-if="!design.proofs || !design.proofs.data || !design.proofs.data.length">
				<v-img max-width="150" max-height="150" contain :src="'http://www.rushordertees.com/design/ZoomImage.php?src=' + design.designId + '_f&x=310&y=350&width=395&height=420&ext=jpg&scale=1'"/>
			</v-col>
			<v-col cols="1" v-if="!design.proofs || !design.proofs.data || !design.proofs.data.length">
				<v-img max-width="150" max-height="150" contain :src="'http://www.rushordertees.com/design/ZoomImage.php?src=' + design.designId + '_b&x=310&y=350&width=395&height=420&ext=jpg&scale=1'"/>
			</v-col>
			<v-col cols="8">
				<h5>Design #{{d + 1}} ({{design.designId}})</h5>
				<h3>{{design.name}} - {{design.PO}}</h3>
				<p v-for="(item, i) in design.items.data" :key="i">
					<span>{{item.label}} - {{item.style}} - {{item.color}}</span> &nbsp;
					<span v-for="(size, s) in item.sizes.data" :key="s" v-show="size.quantity">{{size.sizeCode}}({{size.quantity}}) &nbsp;</span>
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<style scoped>
.proof {
  border: 0;
}
.proof:hover {
	transform: scale(4,4);
	transform-origin: top left;
	transition: all .2s ease;
	z-index: 999;
}
.v-input-selection-controls {
	margin-top: 1px;
}
</style>

<script>
import axios from '../api';

export default {
	name: 'OrderDetail',
	data: () => ({
		file: '',
    _uploadFormOpen: false,
    get uploadFormOpen() {
      return this._uploadFormOpen;
    },
    set uploadFormOpen(value) {
      this._uploadFormOpen=value;
    },
	}),
	props: ['order'],
	methods: {
		cheapestForAll() {
			this.$parent.$refs.OrderMultiShip.cheapestForAll();
		},

		downloadTemplate() {
			axios.get(`/orders/${this.order.id}/shipping/multiship/xls`, {
				params: {}
			})
			.then(response => {
				//alert(response.data.file);
				let url = `https://printfly-labels.s3.amazonaws.com/${response.data.file}`;
				window.open(url, '_self');
				console.log('File downloaded successfully!!');
			})
			.catch(function() {
				console.log('File download FAILURE!!');
			});
		},

		handleFileUpload() {
			this.file = this.$refs.file.$refs.input.files[0];
		},

		newShipment() {
			this.$parent.$refs.OrderMultiShip.newShipment();
		},

		printAll() {
			this.$parent.$refs.OrderMultiShip.printAll();
		},
	
		shipAll() {
			this.$parent.$refs.OrderMultiShip.shipAll();
		},

		submitFile() {
			let formData = new FormData();
			formData.append('name', 'shipments');
			formData.append('file', this.file);
			axios.post(`/files/multiship/${this.order.id}`, formData, {
				params: {
					"include": "all"
				}
			})
			.then(response => {
				response.data.data.forEach((el) => {
					el.dirty = false;
					el.editing = false;
					el.cheapestMethod = null;
				});
				this.order.shippingMultiples.data = response.data.data;
				console.log('File ingested successfully!!');
				this.cheapestForAll();
			})
			.catch(function() {
				console.log('File ingestion FAILURE!!');
			});
		},

		trackingCSV() {
			this.$parent.$refs.OrderMultiShip.trackingCSV();
		},

		voidAll() {
			this.$parent.$refs.OrderMultiShip.voidAll();
		}
	}
}
</script>
