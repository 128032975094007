<template>
	<v-container>
		<v-list v-if="order && order.shippingMultiples && order.shippingMultiples.data && order.compactView">
			<v-list-item v-for="(shipment, s) in order.shippingMultiples.data" :key="s">
				<v-list-item-content>
					<v-row class="text-left">
						<v-col cols=3>
							<p class="font-weight-bold">
								{{shipment.address.data[0].shipTo}}<br/>
								{{shipment.address.data[0].address1}}<br/>
								{{shipment.address.data[0].address2}}<br v-if="shipment.address.data[0].address2"/>
								{{shipment.address.data[0].address3}}<br v-if="shipment.address.data[0].address3"/>
								{{shipment.address.data[0].city}}, {{shipment.address.data[0].state}} {{shipment.address.data[0].zip}}
							</p>
						</v-col>
						<v-col cols=3>
							<p>
								<span class="text-body-2 font-weight-bold">Tracking:</span> &nbsp;
								<span v-if="shipment.carrier.toLowerCase() === 'endicia'" v-show="shipment.upsTrackingNumber" class="text-body-2">
									<a :href="`https://tools.usps.com/go/TrackConfirmAction.action?tRef=fullpage&tLabels=${shipment.upsTrackingNumber}`" target="_blank">
										{{shipment.upsTrackingNumber}}
									</a>
								</span>
								<span v-if="shipment.carrier.toLowerCase() === 'ups'" v-show="shipment.upsTrackingNumber" class="text-body-2">
									<a :href="`https://wwwapps.ups.com/WebTracking/processInputRequest?InquiryNumber1=${shipment.upsTrackingNumber}&TypeOfInquiryNumber=T`" target="_blank">
										{{shipment.upsTrackingNumber}}
									</a>
								</span>
								<span v-if="shipment.carrier.toLowerCase() === 'fedex'" v-show="shipment.upsTrackingNumber" class="text-body-2">
									<a :href="`https://www.fedex.com/fedextrack/?tracknumbers=${shipment.upsTrackingNumber}&cntry_code=us&language=en`" target="_blank">
										{{shipment.upsTrackingNumber}}
									</a>
								</span>
								<span v-show="!shipment.upsTrackingNumber" class="text-body-2">Not shipped.</span>
							</p>
						</v-col>
					</v-row>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<v-expansion-panels v-if="order && order.shippingMultiples && order.shippingMultiples.data && !order.compactView">
			<v-expansion-panel v-for="(shipment, s) in order.shippingMultiples.data" :key="s">
				<v-expansion-panel-header>
					<v-row class="text-left">
						<v-col cols=3>
							<p class="font-weight-bold">
								{{shipment.address.data[0].shipTo}}<br/>
								{{shipment.address.data[0].address1}}<br/>
								{{shipment.address.data[0].address2}}<br v-if="shipment.address.data[0].address2"/>
								{{shipment.address.data[0].address3}}<br v-if="shipment.address.data[0].address3"/>
								{{shipment.address.data[0].city}}, {{shipment.address.data[0].state}} {{shipment.address.data[0].zip}}
							</p>
						</v-col>
						<v-col cols=4>
							<p v-for="(item, i) in shipment.shipment_items" :key="i" class="text-body-2">
								<span>{{item.po}} - </span>
								<span>{{item.style}} - </span>
								<span class="text-uppercase">{{item.color}} - </span>
								<span>{{item.size}} </span>
								<span>({{item.quantity}})</span>
							</p>
						</v-col>
						<v-col cols=3>
							<p v-show="shipment.shippedDate">
								<span class="text-body-2 font-weight-bold">Shipped:</span>
								<span class="text-body-2"> {{shipment.shippedDate}}</span>
							</p>
							<p v-show="shipment.upsTrackingNumber">
								<span class="text-body-2 font-weight-bold">Tracking:</span> &nbsp;
								<span class="text-body-2">{{shipment.upsTrackingNumber}}</span>
							</p>
							<p v-if="!shipment.shippedDate && shipment.cheapestMethod">
								<span class="text-body-2 font-weight-bold">Cheapest Method:</span> &nbsp;
								<span class="text-body-2">{{shipment.cheapestMethod.name}} - ${{shipment.cheapestMethod.cost}}</span>
							</p>
							
							<p v-if="!shipment.shippedDate && shipment.cheapestMethod && shipment.cheapestMethod.id !== shipment.shippingMethodId">
								<a class="text-body-2 font-weight-bold" v-on:click.stop="useCheapestMethod(shipment)">Use the cheapest method</a>
							</p>
							<p v-show="shipment.shippingMethodDescription">
								<span class="text-caption">{{shipment.shippingMethodDescription}} - {{shipment.weight}} lbs</span>
							</p>
						</v-col>
						<v-col cols=2>
							<v-row class="float-right">
								<v-btn small color="primary" v-if="!shipment.dirty && !shipment.shippedDate" v-on:click.stop="shipOne(shipment)">SHIP</v-btn>
								<div v-if="shipment.shippedDate && shipment.upsTrackingNumber" class="float-right">
									<v-btn small color="primary" class="mr-2 float-right" v-if="shipment.carrier.toLowerCase() === 'endicia'" target="_blank" 
										:href="`https://tools.usps.com/go/TrackConfirmAction.action?tRef=fullpage&tLabels=${shipment.upsTrackingNumber}`">TRACK</v-btn>
									<v-btn small color="primary" class="mr-2 float-right" v-if="shipment.carrier.toLowerCase() === 'ups'" target="_blank"
										:href="`https://wwwapps.ups.com/WebTracking/processInputRequest?InquiryNumber1=${shipment.upsTrackingNumber}&TypeOfInquiryNumber=T`">TRACK</v-btn>
									<v-btn small color="primary" class="mr-2 float-right" v-if="shipment.carrier.toLowerCase() === 'fedex'" target="_blank"
										:href="`https://www.fedex.com/fedextrack/?tracknumbers=${shipment.upsTrackingNumber}&cntry_code=us&language=en`">TRACK</v-btn>
								</div>
								<v-btn small color="secondary" 
									class="ml-2 float-right" 
									v-if="shipment.shippedDate && shipment.upsTrackingNumber"
									v-on:click.stop="shippingTransactionVoid(shipment)"
								>void</v-btn>
								<v-btn small color="secondary"
									class="ml-2 float-right"
									v-show="!shipment.shippedDate && !shipment.cheapestMethod"
									v-on:click.stop="getCheapestMethod(shipment)"
								>Cheapest</v-btn>
								<v-progress-circular indeterminate size="16" v-if="shipment.gettingCheapest"></v-progress-circular>
							</v-row>
						</v-col>
					</v-row>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<ShipmentForm ref="ShipmentForm" v-show="shipment.editing" v-bind:shipment="shipment" v-bind:designOptions="designOptions"/>
					<v-row>
						<v-col cols=4>
							<p v-for="(pkg, p) in shipment.packages.data" :key="p">
								<span class="font-weight-bold">Package {{p + 1}}</span> Weight: {{pkg.weight}}lbs.
								<v-btn x-small color="primary" v-if="pkg.tracking">TRACK</v-btn>
								<v-icon v-on:click.stop="deletePackage(shipment, pkg)" v-if="!pkg.tracking" right dark>mdi-delete-forever</v-icon>
							</p>
						</v-col>
						<v-col cols=4>
							<div v-if="shipment.shippedDate && shipment.upsTrackingNumber">
								<h4>Tracking:</h4>
								<p>{{shipment.upsTrackingNumber}}</p>
							</div>
							<h4>Notes:</h4>
							<p>{{shipment.notes}}</p>
							<p v-show="!shipment.notes">No notes added.</p>
						</v-col>
						<v-col cols=4>
							<v-card>
								<h4>Label Message 1</h4>
								<p v-show="shipment.ref1">{{shipment.ref1}}</p>
								<p v-show="!shipment.ref1">No message.</p>
							</v-card>
							<v-card>
								<h4>Label Message 2</h4>
								<p v-show="shipment.ref2">{{shipment.ref2}}</p>
								<p v-show="!shipment.ref2">No message.</p>
							</v-card>
						</v-col>					
					</v-row>
					<v-row>
						<v-col cols=12>
							<v-col cols=2>
								<v-text-field class="float-left" v-model="shipment.weight_input" label="Weight"></v-text-field>
								<v-btn small class="float-left" color="secondary" v-on:click.stop="addPackageToShipment(shipment)">Add Package</v-btn>
							</v-col>
							<v-spacer></v-spacer>
							<v-btn v-if="!order.isVendor" small class="ml-2 float-right" color="red" v-on:click.stop="deleteShipment(shipment)">Delete</v-btn>
							<v-btn v-if="!order.isVendor" small class="ml-2 float-right" color="secondary" v-on:click.stop="duplicateShipment(shipment)">Duplicate</v-btn>
							<v-btn 
								v-if="!order.isVendor"
								v-show="!shipment.editing" 
								small class="ml-2 float-right" color="secondary" 
								v-on:click.stop="shipment.editing = true">Edit</v-btn>
						</v-col>
					</v-row>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</v-container>
</template>

<script>
import axios from '../api';
import _cloneDeep from 'lodash/clone';
import ShipmentForm from './ShipmentForm';

export default {
	name: 'OrderMultiShip',
	props: ['order'],
	components: {
		ShipmentForm,
	},
	data: () => ({
		designOptions: {},
		labelArray: [],
		shipAllLimit: 25
	}),
	methods: {
		addPackageToShipment(shipment)
		{
			let newPackage = {
				"weight": shipment.weight_input
			};
			newPackage = _cloneDeep(newPackage);

			axios.post(`/orders/shipping/${shipment.id}/package/add`, {
				newPackage
			})
			.then(async response => {
				// Handle response
				console.log(response.data);
				shipment.dirty = false;
				shipment.editing = false;
				shipment.weight_input = '';
				this.notifications.showNotification('Successfully added package.', 'success');
				shipment.packages.data.push(newPackage);
				shipment.weight = Number.parseFloat(shipment.packages.data.reduce(function(a, b) {return a + parseFloat(b.weight); }, 0)).toFixed(2);
				this.getCheapestMethod(shipment);
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});

		},

		async cheapestForAll() {
			for (let shipment of this.order.shippingMultiples.data) {
				if (!shipment.shippedDate) {
					await this.getCheapestMethod(shipment);
				}
			}
		},

		createCSV(data) {
			let csvRows = [];
			const headers = Object.keys(data[0]);
			csvRows.push(headers.join(','));
			for (const row of data) {
				const values = headers.map(e => {
					return row[e];
				});
				csvRows.push(values.join(','));
			}
			return csvRows.join('\n')
		},

		deletePackage(shipment, pkg) {
			axios.post(`/orders/shipping/package/remove`, {
				pkg
			})
			.then(async response => {
				// Handle response
				console.log(response.data);
				console.log(pkg);
				shipment.packages.data.splice(shipment.packages.data.indexOf(pkg), 1);
				this.notifications.showNotification('Successfully removed package.', 'success');
				this.getCheapestMethod(shipment);
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		deleteShipment(shipment) {
			axios.post(`/orders/shipping/remove`, {
				shipment
			})
			.then(async response => {
				// Handle response
				console.log(response.data);
				this.order.shippingMultiples.data.splice(this.order.shippingMultiples.data.indexOf(shipment), 1);
				this.notifications.showNotification('Successfully removed shipment.', 'success');
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		downloadCSV(data) {
			const blob = new Blob([data], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.setAttribute('href', url);
			a.setAttribute('download', 'download.csv');
			a.click();
		},

		duplicateShipment(shipment) {
			let dupe = _cloneDeep(shipment);
			dupe.id = null;
			dupe.addressId = null;
			dupe.address.data[0].id = null;
			dupe.packages = [];
			dupe.shippedDate = null;
			dupe.dirty = true;
			dupe.editing = true;
			dupe.itemsDirty = true;
			this.order.shippingMultiples.data.push(dupe);
		},

		getCheapestMethod(shipment) {
			shipment.gettingCheapest = true;
			axios.get(`/shipping/cheapest`,
				{
					params: {
						'addressId': shipment.addressId,
						'dueDate': shipment.scheduledShipDate,
						'quantity': shipment.quantity,
						'items': shipment.shipment_items,
						'packages': shipment.packages.data
					}
				}
			).then(async response => {
				// Handle response
				shipment.cheapestMethod = response.data.data;
				this.useCheapestMethod(shipment);
				shipment.gettingCheapest = false;
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		async printAll() {
			this.labelArray = [];
			let pkg_idx = 0;
			let pkg_ct = 0;
			let labelPackage = {};
			const prefix = 'https://eztees-shipping-labels.s3.amazonaws.com/';

			for (let shipment of this.order.shippingMultiples.data) {
				console.log(shipment);
				pkg_idx = 0;
				pkg_ct = shipment.packages.data.length;
				for (let pkg of shipment.packages.data) {
					if (pkg.trackingNumber) {
						labelPackage = {"pkg": {"filename": `${prefix}${pkg.carrier}/label-${pkg.trackingNumber}.png`}};
						this.labelArray.push({'pkg': labelPackage.pkg, 'i': ++pkg_idx, 'c': pkg_ct, 'items': shipment.shipment_items})
					}
				}
			}
			await this.printLabels();
		},

		async printLabels() {
			let printFrame = document.createElement("iframe");
			let frameHtml = `<body style="margin:0;padding:0;border:0;height:1080px;width:540px;">`;
			let labelPromises = [];
			this.labelArray.forEach(labelPackage => {
				let label = new Image();
				let labelItems = `<p style="font-family:arial sans-serif;font-size:110%;font-weight:bold;">Package ${labelPackage.i} of ${labelPackage.c}</p>`;
				if (labelPackage.i === 1) {
					labelPackage.items.forEach(item => {
						labelItems += `<p><span>${item.po} - </span>`;
						labelItems += `<span>${item.style} - </span>`;
						labelItems += `<span class="text-uppercase">${item.color} - </span>`;
						labelItems += `<span>${item.size} </span>`;
						labelItems += `<span>(${item.quantity})</span></p>`;
					});
				}
				printFrame.style.visibility = 'hidden';
				label.src = labelPackage.pkg.filename;
				labelPromises.push(new Promise((resolve) => {
					label.onload = () => {
						frameHtml += `<div><img style="display: block; width: 100%; margin:0;padding:0;border:0;" src="${label.src}"/><div style="display:block;width:100%;margin:5px 0 0 0;padding:0 0 0 5px;">${labelItems}</div></div>`;
						resolve();
					};
				}));
			});
			return Promise.all(labelPromises).then(async () => {
				frameHtml += '</body>';
				document.body.appendChild(printFrame);
				printFrame.contentWindow.document.open();
				printFrame.contentWindow.document.write(frameHtml);
				printFrame.contentWindow.document.close();
				printFrame.contentWindow.focus();
				await new Promise(r => setTimeout(r, 500 + (this.labelArray.length * 20)));
				printFrame.contentWindow.print();
				printFrame.remove();
			});
		},

		async shipAll() {
			this.labelArray = [];
			let maxweight = 30;
			let promises = [];
			for (let shipment of this.order.shippingMultiples.data) {
				if (this.labelArray.length >= this.shipAllLimit) {
					continue;
				}
				let heavy = shipment.packages.data.filter(pkg => pkg.weight >= maxweight);
				if (heavy.length) {
					this.notifications.showNotification(`Shipments over ${maxweight}lbs must be shipped manually.`, '');
				}
				if (!shipment.shippedDate && !heavy.length) {
					promises.push(this.shipTransaction(shipment));
				}
			}
			Promise.all(promises)
			.then(() => {
				return this.printLabels();
			});
		},

		async shipOne(shipment) {
			this.labelArray = [];
			await this.shipTransaction(shipment);
			await this.printLabels();
		},

		async shipTransaction(shipment) {
			return new Promise((resolve, reject) => {
				let url = `/shipping/${shipment.id}/shipBackup/LOCAL_PRINTER/486`;
				axios.get(url, {}).then(async response => {
					shipment.shippedDate = response.data.data.shippedDate;
					shipment.upsTrackingNumber = response.data.data.upsTrackingNumber;
					shipment.packages = response.data.data.packages;
					let i = 0;
					let c = shipment.packages.length;
					let promises = [];
					for (let pkg of response.data.data.packages) {
						promises.push(new Promise((resolve) => {
							resolve(this.labelArray.push({'pkg': pkg, 'i': ++i, 'c': c, 'items': shipment.shipment_items}));
						}));
					}
					console.log('SUCCESS!!');
					resolve(Promise.all(promises));
				})
				.catch(function(){
					console.log('FAILURE!!');
					reject();
				});
			});
		},

		shippingTransactionVoid(shipment) {
			axios.post(`/shipping/${shipment.id}/void`, {})
			.then(async response => {
				// Handle response
				shipment.shippedDate = null;
				shipment.upsTrackingNumber = null;
				this.notifications.showNotification('Successfully voided shipment.', 'success');
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		trackingCSV() {
			let shipments = [];
			for (let shipment of this.order.shippingMultiples.data) {
				for (let pkg of shipment.packages.data) {
					if (pkg.trackingNumber) {
						shipments.push({
							"Name": shipment.address.data[0].shipTo,
							"Tracking": pkg.trackingNumber
						});
					}
				}
			}
			const csvdata = this.createCSV(shipments);
			this.downloadCSV(csvdata);
		},

		useCheapestMethod(shipment) {
			axios.post(`/shipping/${shipment.id}/method/update`, {
				shippingMethodId: shipment.cheapestMethod.id
			})
			.then(async response => {
				// Handle response
				shipment.shippingMethodId = shipment.cheapestMethod.id;
				shipment.shippingMethodDescription = shipment.cheapestMethod.name;
				this.notifications.showNotification('Successfully applied cheapest method.', 'success');
				console.log('SUCCESS!!');
				return response.data;
			})
			.catch(function(){
				console.log('FAILURE!!');
			});
		},

		async voidAll() {
			for (let shipment of this.order.shippingMultiples.data) {
				if (shipment.shippedDate) {
					//console.log(shipment);
					await this.shippingTransactionVoid(shipment);
				}
			}
		},

		newShipment() {
			let shipment = {
				"id": null,
				"addressId": null,
				"attentionTo": null,
				"attentionToName": null,
				"attentionToPartyId": null,
				"branding": "rushordertees.com",
				"carrier": "",
				"insured": 0,
				"notes": "",
				"orderId": this.order.id,
				"quoteId": null,
				"quantity": 0,
				"type": "multiple",
				"shipment_items": [],
				"ref1": null,
				"ref2": `Order #${this.order.id}`,
				"shippedDate": null,
				"saturdayRequest": 0,
				"scheduledShipDate": "",
				"shippingConfirmationId": 1,
				"shippingMethodDescription": "USPS First Class",
				"shippingMethodId": 22,
				"shippingCost": null,
				"upsTrackingNumber": null,
				"ignore_fulfillment_date": true,
				"address": {
					"data": [
						{
							"id": null,
							"account_address_id": null,
							"addressTypeId": null,
							"type": null,
							"shipTo": "",
							"address1": "",
							"address2": "",
							"city": "",
							"state": "",
							"stateName": "",
							"stateId": null,
							"zip": "",
							"country": "USA",
							"isDefault": 1,
							"validationOverride": null,
							"orgname": null,
							"residential": 0,
							"isValidated": 0,
							"active": 1
						}
					]
				},
				"packages": {
					"data": []
				},
				"dirty": true,
				"editing": true
			};
			this.order.shippingMultiples.data.push(shipment);
		}
	},
	mounted() {
		let pos = [];
		let colors = [];
		let sizes = [];
		let styles = [];
		this.order.designs.data.map(function(design) {
			console.log(design);
			// Gather colors.
			colors.push(...design.items.data.map(function(item) {
				return item.color;
			}));
			pos.push(design.PO);
			// Gather and filter sizes.
			sizes.push(...design.items.data.map(function(item) {
				return item.sizes.data.map(function(size) {
					if (size.quantity) {
						return size.sizeCode;
					}
				});
			})[0]);
			sizes = sizes.filter(size => size !== undefined);
			// Gather styles.
			styles.push(...design.items.data.map(function(item) {
				return item.style;
			}));
		});
		this.designOptions.colors = colors;
		this.designOptions.pos = pos;
		this.designOptions.sizes = sizes;
		this.designOptions.styles = styles;
		console.log(this.designOptions);
		this.notifications = this.$parent.$refs.Notifications;
	},
}
</script>
