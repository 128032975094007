<template>
	<v-container>
		<v-row>
			<v-col cols="4">
				<h2>Login</h2>
				<p v-if="$route.query.redirect">
					You need to login first.
				</p>
				<form @submit.prevent="login">
					<v-text-field 
						label="Username" 
						v-model="email"
						hide-details="auto"
					></v-text-field>
					<v-text-field 
						label="Password" 
						v-model="pass" 
						placeholder="password" 
						type="password"
					></v-text-field>
					<v-btn type="submit">login</v-btn>
					<p v-if="error" class="error">Bad login information</p>
				</form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import auth from '../auth'
export default {
	data () {
		return {
			email: '',
			pass: '',
			error: false
		}
	},
	methods: {
		login() {
			auth.login(this.email, this.pass, loggedIn => {
				if (!loggedIn) {
					this.error = true;
				} else {
					this.$router.replace(this.$route.query.redirect || '/');
				}
			})
		}
	}
}
</script>

<style>
.error {
	color: red;
}
</style>