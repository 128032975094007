import axios from 'axios'

const api = axios.create({
	baseURL: process.env.VUE_APP_LEGACY_API_URL,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache'
	}
});
api.defaults.timeout = 30000;
api.interceptors.request.use(
  (config) => {
		const token = localStorage.token;
		if (token) {
			config.headers.common["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
  (error) => Promise.reject(error)
);
export default api