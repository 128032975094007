<template>
	<v-container>
		<Notifications ref="Notifications" />
		<OrderDetail ref="OrderDetail" v-if="order && order.designs" v-bind:order="order"/>
		<OrderMultiShip ref="OrderMultiShip" v-if="order && order.shippingMultiples" v-bind:order="order"/>
	</v-container>
</template>

<script>
import auth from '../auth'
import axios from '../api';
import Notifications from "./Notifications";
import OrderDetail from './OrderDetail';
import OrderMultiShip from './OrderMultiShip';

export default {
	name: 'Order',

	components: {
		OrderDetail,
		OrderMultiShip,
		Notifications,
	},

	data: () => ({
		order: {
			compactView: false
		}
	}),

	mounted() {
		const orderId = this.$props.orderId;
		axios.get(`/orders/${orderId}?include=designs,shippingMultiples&v=${new Date().getTime()}`, {})
		.then(response => {
			response.data.data.shippingMultiples.data.forEach((el) => {
				el.dirty = false;
				el.editing = false;
				el.cheapestMethod = null;
				el.gettingCheapest = false;
				el.weight = Number.parseFloat(el.packages.data.reduce(function(a, b) {return a + parseFloat(b.weight); }, 0)).toFixed(2);
				return el;
			});
			response.data.data.vendorNames = auth.isVendor() ? auth.getVendorNames() : [];
			if (typeof(response.data.data.vendorNames) === 'string') {
				response.data.data.vendorNames = [response.data.data.vendorNames];
			}
			response.data.data.isVendor = auth.isVendor() ? true : false;
			this.order = response.data.data;
		});
	},

	props: ['orderId'],
};
</script>
